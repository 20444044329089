jQuery(document).on('turbolinks:load', () => {
  $('.edit_practice').each((_, element) => {
    let form = element as HTMLFormElement;
    if (form.dataset.viewOnly === 'true') {
      $('input, select, textarea, button', element).prop('disabled', true);
    } else {
      $('.practice-fieldset-fields', element).hide();

      $('.practice-fieldset-legend', element).on('click', function (event) {
        event.preventDefault();

        $('.practice-fieldset-fields').hide();
        $(this).next('.practice-fieldset-fields').show();
      });
    }
  });
});
