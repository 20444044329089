import 'datatables.net-bs4';
import 'datatables.net-rowgroup-bs4';

// require('datatables.net-bs4')(jQuery, window);
// require('datatables.net-rowgroup-bs4')(jQuery, window);

jQuery(document).on('turbolinks:load', function () {
  $('#practices-scheduled-only-table').dataTable();
  $('#existing-patient-charges-table').dataTable();

  $('#agents-commissions-table').dataTable({
    columns: [
      { data: 'practice_name' },
      { data: 'agent_name' },
      { data: 'did_np_show_up' },
      { data: 'total_commission' },
    ],
    rowGroup: {
      enable: true,
      dataSrc: 'practice_name',
      endRender: function (rows, group) {
        var rowTotal = 0.0;
        rows.nodes().each(function (el, i) {
          rowTotal += parseFloat($('.total-np-showed-hidden', el).val());
        });

        return $('<tr >')
          .append('<td colspan="3" />')
          .append('<td>$' + rowTotal.toFixed(2) + '</td>');
      },
    },
  });

  $('#agents-commissions-overall-table').dataTable();

  $('#missed-showup-appts-table').dataTable();
});
