/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import 'moment';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@nathanvda/cocoon';
import 'select2';
import 'inputmask/dist/jquery.inputmask';
import 'tempusdominus-bootstrap-4';
import '@stripe/stripe-js';

import '../stylesheets/application';

import '../controllers';

import '../src/admin_reports';
import '../src/appointments';
import '../src/client_reports';
import '../src/phone_calls';
import '../src/practices';
import '../src/subscriptions';

jQuery(document).on('turbolinks:load', function () {
  $('.date-time-picker').datetimepicker({
    focusOnShow: false,
    icons: {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-calendar-check-o',
      clear: 'fr fa-trash',
      close: 'fa fa-times',
    },
  });

  $('#start-date-picker').datetimepicker({
    format: 'L',
    focusOnShow: false,
    icons: {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-calendar-check-o',
      clear: 'fr fa-trash',
      close: 'fa fa-times',
    },
  });

  $('#start-date-picker').on('change.datetimepicker', function (event) {
    $('#end-date-picker').datetimepicker('minDate', event.date);
  });

  $('#end-date-picker').datetimepicker({
    format: 'L',
    focusOnShow: false,
    icons: {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-calendar-check-o',
      clear: 'fr fa-trash',
      close: 'fa fa-times',
    },
  });
  $('#end-date-picker').on('change.datetimepicker', function (event) {
    $('#start-date-picker').datetimepicker('maxDate', event.date);
  });

  $('#month-picker').datetimepicker({
    viewMode: 'years',
    format: 'MM/YYYY',
    focusOnShow: false,
    icons: {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-calendar-check-o',
      clear: 'fr fa-trash',
      close: 'fa fa-times',
    },
  });

  $('.date-picker').datetimepicker({
    format: 'MM/DD/YYYY',
    focusOnShow: false,
    icons: {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      today: 'fa fa-calendar-check-o',
      clear: 'fr fa-trash',
      close: 'fa fa-times',
    },
  });

  $('.reveal-target').each(function (i, el) {
    var $el = $(el);
    var targetSelector = $el.data('target');
    var $target = $(targetSelector);

    if (!$el.prop('checked')) {
      $target.addClass('d-none');
    }

    $el.on('change', function (event) {
      if (!$el.prop('checked')) {
        $target.addClass('d-none');
      } else {
        $target.removeClass('d-none');
      }
    });
  });

  $('.phone-mask').inputmask('+1 (999) 999-9999');
});
