jQuery(document).on('turbolinks:load', () => {
  var qIndex = window.location.href.indexOf('?');
  var queryString = qIndex == -1 ? '' : window.location.href.slice(qIndex + 1);

  document
    .querySelectorAll('[data-appointments-datatable]')
    .forEach((element) => {
      const userRole = JSON.parse(jQuery('#user-role').val())[0];
      const containerElement = jQuery(element);
      const tableElement = jQuery('table', element);
      const dataTable = tableElement.DataTable({
        serverSide: true,
        ajax: '/appointments.json?' + queryString,
        columns: [
          {
            orderable: false,
            data: null,
            defaultContent: `
        <div class="d-flex justify-content-center">
          <a class="text-primary open-modal ml-2" role="button"><i class="fa fa-edit"></i></a>
        </div>
      `,
          },
          {
            data: 'time',
            render: (data, type, row, meta) => {
              const select = $(
                `<input class="form-control datetimepicker-input" autocomplete="off" data-toggle="datetimepicker" data-target="#appt-time-${meta.row}" />`,
              );
              select.attr('value', data);

              const inputGroup = $(
                `<div class="input-group date date-time-picker" data-target-input="nearest" id="appt-time-${meta.row}" data-id="${row.id}" />`,
              );
              inputGroup.append(
                select,
                $(
                  `<div class="input-group-append" data-toggle="datetimepicker" data-target="#appt-time-${meta.row}"><div class="input-group-text"><i class="fa fa-calendar"></i></div></div>`,
                ),
                $(
                  `<div class="input-group-append"><div class="input-group-text">${row.time_zone}</div></div>`,
                ),
              );

              return $('<div />').append(inputGroup).html();
            },
          },
          { data: 'id' },
          { data: 'practice_name' },
          { data: 'patient_name', orderable: false },
          {
            data: 'patient_showed_up',
            render: (data, type, row, meta) => {
              const select = $(
                `<select class="form-control" data-id="${row.id}" data-patient-showed />`,
              ).append(
                '<option></option><option value="No">No</option><option value="Yes">Yes</option>',
              );

              if (data.length > 0) {
                select
                  .find(`option[value=${data}]`)
                  .attr('selected', 'selected');
              }

              return $('<div />').append(select).html();
            },
          },
        ],
        order: [[1, 'desc']],
      });

      tableElement.on('draw.dt', () => {
        $('#appointments-datatable .date-time-picker').datetimepicker({
          focusOnShow: false,
          icons: {
            time: 'fa fa-clock',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-calendar-check-o',
            clear: 'fr fa-trash',
            close: 'fa fa-times',
          },
        });
      });

      tableElement.on('change', 'select[data-patient-showed]', (event) => {
        $.ajax({
          url: `/appointments/${event.target.dataset.id}.json`,
          method: 'patch',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: JSON.stringify({
            appointment: {
              patient_showed_up:
                event.target.value == ''
                  ? null
                  : event.target.value == 'Yes'
                  ? true
                  : false,
            },
          }),
        });
      });

      tableElement.on('change.datetimepicker', '.date-time-picker', (event) => {
        if (event.oldDate === null) {
          return;
        }

        const currentDate = $(event.currentTarget).datetimepicker('viewDate');
        $.ajax({
          url: `/appointments/${event.target.dataset.id}.json`,
          method: 'patch',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: JSON.stringify({
            appointment: {
              time: currentDate.format('M/D/Y h:mm A'),
            },
          }),
        });
      });

      const $agentFormModal = jQuery(
        '.appointment-agent-modal',
        containerElement,
      );
      const onLoadEditModal = () => {
        if (
          $agentFormModal.length > 0 &&
          !$agentFormModal.hasClass('inactive')
        ) {
          $agentFormModal.modal({ show: false });
          $agentFormModal.on('click', '.close', function (event) {
            event.preventDefault();

            $agentFormModal.modal('hide');
          });
          $('.date-time-picker', $agentFormModal).datetimepicker({
            focusOnShow: false,
            icons: {
              time: 'fa fa-clock',
              date: 'fa fa-calendar',
              up: 'fa fa-arrow-up',
              down: 'fa fa-arrow-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-calendar-check-o',
              clear: 'fr fa-trash',
              close: 'fa fa-times',
            },
          });
          $agentFormModal.on('submit', 'form', function (event) {
            event.preventDefault();

            var $form = $(this);
            var data = $form.serialize();

            $.ajax({
              url:
                '/appointments/' +
                $agentFormModal.data('appointmentid') +
                '.json',
              method: 'put',
              data: data,
              success: function (response) {
                dataTable.ajax.reload(undefined, false);
                $agentFormModal.modal('hide');
              },
            });
          });
        }
      };

      tableElement.on('click', 'tbody .open-modal', function (event) {
        var appointmentId = parseInt(
          dataTable.row($(this).parents('tr')).data().id,
        );
        $agentFormModal.data('appointmentid', appointmentId);

        $.ajax({
          url: `/appointments/${appointmentId}/edit`,
          method: 'get',
          success: function (response) {
            $agentFormModal.modal('show');
            $agentFormModal.html(response);

            if (userRole !== 'client' && userRole !== 'client_staff') {
              onLoadEditModal();
            }
          },
        });
      });
    });
});
