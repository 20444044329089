import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

type TemplateVariable = {
  id?: number;
  name: string;
  description: string;
  path: string;
};

type SaveEvent = PointerEvent & {
  params: {
    idx: number;
  };
};

type DeleteEvent = PointerEvent & {
  params: {
    idx: number;
  };
};

export default class extends Controller {
  static targets = ['tableBody'];

  declare tableBodyTarget: HTMLElement;

  templateVariables: TemplateVariable[] = [];

  async connect() {
    try {
      const response = await axios.get<{
        template_variables: TemplateVariable[];
      }>('/manage/template_variables.json');

      for (let idx in response.data.template_variables) {
        this.addRow(response.data.template_variables[idx]);
      }
    } catch (e) {
      alert(e);
    }
  }

  addRow(toAdd?: TemplateVariable) {
    const idx = this.tableBodyTarget.childNodes.length - 1;
    const row = document.createElement('tr');
    const tvar = toAdd || { name: '', path: '', description: '' };
    this.templateVariables.push(tvar);

    row.innerHTML = `<tr>
        <td><input type="text" name="name" class="form-control" value="${
          tvar.name || ''
        }" /></td>
        <td><textarea name="description" class="form-control">${
          tvar.description || ''
        }</textarea></td>
        <td><input type="text" name="path" class="form-control" value="${
          tvar.path || ''
        }" /></td>
        <td>
          <button class="btn btn-primary" data-action="template-variables#save:prevent" data-template-variables-idx-param="${idx}">Save</button>
          <button class="btn btn-danger ml-2" data-action="template-variables#delete:prevent" data-template-variables-idx-param="${idx}">Delete</button>
        </td>
      </tr>`;
    this.tableBodyTarget.append(row);
  }

  async save(event: SaveEvent) {
    const tvar = this.templateVariables[event.params.idx];

    const formData = new FormData();
    this.tableBodyTarget.children[event.params.idx]
      .querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(
        'input,textarea',
      )
      .forEach((field) => {
        formData.set(`template_variable[${field.name}]`, field.value);
      });

    if (tvar.id === undefined) {
      try {
        const response = await axios.post<{
          template_variable: TemplateVariable;
        }>(`/manage/template_variables`, formData);
        this.templateVariables[event.params.idx] =
          response.data.template_variable;
      } catch (e) {
        alert(`Error creating new variable: ${e}`);
      }
    } else {
      try {
        const response = await axios.patch<{
          template_variable: TemplateVariable;
        }>(`/manage/template_variables/${tvar.id}`, formData);
        this.templateVariables[event.params.idx] =
          response.data.template_variable;
      } catch (e) {
        alert(`Error saving variable: ${e}`);
      }
    }
  }

  async delete(event: DeleteEvent) {
    const tvar = this.templateVariables[event.params.idx];
    if (
      tvar.id === undefined ||
      confirm(
        'Are you sure you want to delete this template variable? It will not be rendered if it is currently in use.',
      )
    ) {
      if (tvar.id !== undefined) {
        await axios.delete(`/manage/template_variables/${tvar.id}`);
      }

      this.templateVariables.splice(event.params.idx, 1);
      this.tableBodyTarget.children[event.params.idx].remove();
    }
  }
}
