jQuery(document).on('turbolinks:load', () => {
  var qIndex = window.location.href.indexOf('?');

  $('#phone_call_practice_id').select2();

  const notBookedRowFormat = (data) => {
    return `
      <table class="table table-sm m-0">
        <thead>
          <tr>
            <th class="border-top-0" width="50%">Category</th>
            <th class="border-top-0" width="50%">Reason Not Booked</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td width="50%">${data.not_booked_category}</td>
            <td width="50%">${data.not_booked_reason}</td>
          </tr>
        </tbody>
      </table>
    `;
  };

  const bookedRowFormat = (data) => {
    return data.appointments?.length > 0
      ? `
      <table class="table table-sm m-0">
        <thead>
          <tr>
            <th class="border-top-0" width="30%">Patient Name</th>
            <th class="border-top-0" width="50%">Appt Time</th>
            <th class="border-top-0" width="20%">Showed?</th>
          </tr>
        </thead>

        <tbody>
          ${data.appointments
            ?.map((appointment) => {
              return `
              <tr>
                <td width="40%">${appointment.patient.first_name} ${appointment.patient.last_name}</td>
                <td width="40%">${appointment.time}</td>
                <td width="20%">${appointment.patient_showed_up}</td>
              </tr>
            `;
            })
            .join(' ')}
        </tbody>
      </table>
    `
      : `No appointments`;
  };

  const existingFormat = (data) => {
    return `
      <table class="table table-sm m-0">
        <thead>
          <tr>
            <th class="border-top-0" width="25%">Name</th>
            <th class="border-top-0" width="25%">Phone</th>
            <th class="border-top-0" width="75%">Notes</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td width="25%">${data.patient_first_name || ''} ${
      data.patient_last_name || ''
    }</td>
            <td width="25%">${data.patient_phone || ''}</td>
            <td width="50%">${data.notes || ''}</td>
          </tr>
        </tbody>
      </table>
    `;
  };

  const formatRow = (data) => {
    if (
      data.call_type === 'Existing Patient - Callback' ||
      data.call_type === 'Existing Patient - Complete' ||
      data.call_type === 'Message' ||
      data.call_type === 'Lead Not Booked'
    ) {
      return existingFormat(data);
    } else if (data.call_type == 'NP NOT Booked') {
      return notBookedRowFormat(data);
    } else {
      return bookedRowFormat(data);
    }
  };

  document
    .querySelectorAll('[data-phone-calls-datatable]')
    .forEach((element) => {
      const userRole = JSON.parse(jQuery('#user-role').val())[0];
      var queryString =
        qIndex == -1 ? '' : window.location.href.slice(qIndex + 1);

      const containerElement = jQuery(element);
      const jQueryElement = jQuery('table', containerElement);
      const filters = containerElement.data('filter')?.split(',');
      if (filters) {
        queryString += filters
          .map((filter) => `&call_types[]=${filter}`)
          .join('');
      }

      const phoneCallsDataTable = jQueryElement.DataTable({
        serverSide: true,
        ajax: '/phone_calls.json?' + queryString,
        columns: [
          {
            orderable: false,
            data: null,
            defaultContent: `
            <div class="d-flex justify-content-center">
              <a class="text-primary open-modal ml-2" role="button"><i class="fa fa-edit"></i></a>
            </div>
          `,
          },
          { data: 'timestamp' },
          { data: 'id' },
          { data: 'practice_name' },
          { data: 'call_type' },
          {
            className: 'px-0 py-2',
            render: (data, type, row, meta) => {
              return formatRow(row);
            },
          },
          {
            className: 'px-0 py-2',
            orderable: false,
            data: null,
            render: (data, type, row, meta) => {
              return `<a class="archive" data-practice-id="${
                data.practice_id
              }" data-archived="${data.archived}" href="/phone_calls/${
                data.id
              }.json">${data.archived ? 'Unarchive' : 'Archive'}</a>`;
            },
          },
        ],
        order: [[1, 'desc']],
      });

      jQueryElement.on('click', 'tbody .archive', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        const $target = jQuery(event.target);

        jQuery
          .ajax({
            url: $target.attr('href'),
            method: 'patch',
            data: {
              phone_call: {
                archived: !$target.data('archived'),
                practice_id: $target.data('practice-id'),
              },
            },
          })
          .then(() => {
            jQueryElement.DataTable().ajax.reload(undefined, false);
          });
      });

      jQueryElement.on('click', 'tbody .toggle-details', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        const $this = $(this);
        const $tr = $this.parents('tr');
        const row = phoneCallsDataTable.row($tr);

        if (row.child.isShown()) {
          $this.find('i').removeClass('fa-minus').addClass('fa-plus');
          row.child.hide();
          $tr
            .removeClass('shown p-0')
            .next()
            .children('td')
            .removeClass('bg-light p-0');
        } else {
          $this.find('i').removeClass('fa-plus').addClass('fa-minus');
          row.child(formatRow(row.data())).show();
          $tr.addClass('shown').next().children('td').addClass('bg-light p-0');
        }
      });

      var $agentFormModal = jQuery('.phone-call-agent-modal', containerElement);
      const onLoadEditModal = () => {
        $('#phone_call_practice_id').select2();
        $('#phone_call_practice_id', $agentFormModal).select2();

        if (
          $agentFormModal.length > 0 &&
          !$agentFormModal.hasClass('inactive')
        ) {
          $agentFormModal.modal({ show: false });
          $agentFormModal.on('click', '.close', function (event) {
            event.preventDefault();

            $agentFormModal.modal('hide');
          });
          $agentFormModal.on('submit', 'form', function (event) {
            event.preventDefault();

            var $form = $(this);
            var data = $form.serialize();

            $.ajax({
              url: '/phone_calls/' + $agentFormModal.data('callid') + '.json',
              method: 'put',
              data: data,
              success: function (response) {
                jQueryElement.DataTable().ajax.reload(undefined, false);
                $agentFormModal.modal('hide');
              },
            });
          });
        }
      };

      jQueryElement.on('click', 'tbody .open-modal', function (event) {
        var callId = parseInt(
          phoneCallsDataTable.row($(this).parents('tr')).data().id,
        );
        $agentFormModal.data('callid', callId);

        const url =
          userRole === 'client' || userRole === 'client_staff'
            ? `/phone_calls/${callId}`
            : `/phone_calls/${callId}/edit`;

        $.ajax({
          url,
          method: 'get',
          success: function (response) {
            $agentFormModal.modal('show');
            $agentFormModal.html(response);

            if (userRole !== 'client' && userRole !== 'client_staff') {
              onLoadEditModal();
            }
          },
        });
      });
    });

  var fetchPracticeData = function (practiceId, refetchCustomFields) {
    $.ajax({
      url: '/practices/' + practiceId + '.html',
      success: function (response) {
        $('.tab-content').html(response);

        $('.practice-info-buttons a[data-tab]').removeClass('active show');
        var $link = $('.practice-info-buttons a[data-tab="#tab-exceptions"]');
        if ($link.length > 0) {
          var tabLink = $link.data('tab');
          $link.addClass('active show');
          $(tabLink).addClass('active show');
        }
      },
    });

    $.ajax({
      url: '/practices/' + practiceId + '.json',
      success: function (response) {
        $('#custom-instructions').html(
          response.custom_instructions || 'No custom instructions',
        );
      },
    });

    if (refetchCustomFields) {
      $.ajax({
        url: '/practices/' + practiceId + '.json',
        success: function (response) {
          if (response.stripe_enabled) {
            $('#call-card-input').removeClass('d-none');
          } else {
            $('#call-card-input').addClass('d-none');
          }

          $('#custom-fields').html(
            response.custom_fields.map((customField) => {
              return $(`
                  <div class="form-group">
                    <label class="control-label" for="custom-field-${customField.id}">${customField.label}</label>
                    <input class="form-control" id="custom-field-${customField.id}" name="phone_call[custom_values_attributes][${customField.id}][value]" type="text" />
                    <input name="phone_call[custom_values_attributes][${customField.id}][custom_field_id]" value="${customField.id}" type="hidden" />
                  </div>`);
            }),
          );
        },
      });
    }
  };

  $('#new_phone_call #phone_call_practice_id').on('change', function (event) {
    fetchPracticeData($(this).val(), true);
  });
  if ($('#new_phone_call #phone_call_practice_id').length > 0) {
    fetchPracticeData(
      $('#new_phone_call #phone_call_practice_id').val(),
      $('#custom-fields').children().length === 0,
    );
  }

  $(document).on(
    'cocoon:after-insert',
    function (event, insertedItem, originalEvent) {
      if (event.target.id === 'new-appointments') {
        $('.phone-mask', insertedItem).inputmask('+1 (999) 999-9999');

        const setupDateTimePickerElement = ($el) => {
          const $input = $('input', $el);
          const $icon = $('.input-group-append', $el);

          const pickerId = `${$input.prop('id')}-picker`;
          $el.prop('id', pickerId);
          $input.attr('data-target', `#${pickerId}`);
          $icon.attr('data-target', `#${pickerId}`);
        };

        $('.date-time-picker', insertedItem).each((index, el) => {
          const $el = $(el);
          setupDateTimePickerElement($el);

          $el.datetimepicker({
            focusOnShow: false,
            icons: {
              time: 'fa fa-clock',
              date: 'fa fa-calendar',
              up: 'fa fa-arrow-up',
              down: 'fa fa-arrow-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-calendar-check-o',
              clear: 'fr fa-trash',
              close: 'fa fa-times',
            },
          });
        });

        $('.date-picker', insertedItem).each((index, el) => {
          const $el = $(el);
          setupDateTimePickerElement($el);

          $el.datetimepicker({
            format: 'MM/DD/YYYY',
            focusOnShow: false,
            icons: {
              time: 'fa fa-clock',
              date: 'fa fa-calendar',
              up: 'fa fa-arrow-up',
              down: 'fa fa-arrow-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-calendar-check-o',
              clear: 'fr fa-trash',
              close: 'fa fa-times',
            },
          });
        });
      }
    },
  );
});
