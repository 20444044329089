import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

interface TransactionSuccessResponse {
  success: true;
  transaction: {
    amount: number;
    message: string;
    status: string;
    date: string;
  };
}

interface TransactionErrorResponse {
  success: false;
  errors: { [key: string]: string[] };
}

type TransactionResponse =
  | TransactionSuccessResponse
  | TransactionErrorResponse;

export default class extends Controller {
  static targets = ['amountField', 'error', 'success', 'tbody'];
  static values = { id: String };

  declare amountFieldTarget: HTMLInputElement;
  declare errorTarget: HTMLDivElement;
  declare successTarget: HTMLDivElement;
  declare tbodyTarget: HTMLElement;
  declare idValue: string;

  connect() {}

  async createTransaction() {
    this.element
      .querySelectorAll<HTMLInputElement>('input[type=submit]')
      .forEach((button) => {
        button.disabled = true;
      });

    this.errorTarget.classList.add('d-none');
    this.successTarget.classList.add('d-none');

    const amount = Number(
      this.amountFieldTarget.value.replace(/[^0-9.-]+/g, ''),
    );

    const response = (
      await axios.post<TransactionResponse>('transactions', {
        phone_call_id: this.idValue,
        amount: amount * 100,
      })
    ).data;

    if (response.success) {
      this.successTarget.classList.remove('d-none');
      this.successTarget.textContent = 'The transaction has been submitted.';

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const row = document.createElement('tr');
      row.innerHTML = `<tr>
        <td>${response.transaction.date}</td>
        <td>${formatter.format(response.transaction.amount / 100)}</td>
        <td>${response.transaction.status}</td>
        <td>${response.transaction.message}</td>
      </tr>`;
      this.tbodyTarget.prepend(row);
      this.amountFieldTarget.value = '';
    } else {
      this.errorTarget.classList.remove('d-none');
      this.errorTarget.textContent = response.errors.stripe[0];
    }

    this.element
      .querySelectorAll<HTMLInputElement>('input[type=submit]')
      .forEach((button) => {
        button.disabled = false;
      });
  }
}
