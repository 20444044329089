import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    path: String,
  };

  declare pathValue: String;

  connect() {}

  selectPractice(event: Event) {
    const select = event.target as HTMLSelectElement;
    console.log(this.pathValue.replace(':id', select.value));
    window.location.pathname = this.pathValue.replace(':id', select.value);
  }
}
