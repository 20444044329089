import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import jQuery from 'jquery';

type ToggleSectionEvent = PointerEvent & {
  params: {
    toggle: string;
  };
};

type CallType =
  | 'NP Booked'
  | 'NP NOT Booked'
  | 'Recare Booked'
  | 'Existing Patient - Callback'
  | 'Existing Patient - Complete'
  | 'Message'
  | 'Lead Not Booked';

const formTypePaths = {
  'NP Booked': '/phone_calls/new_form',
  'Recare Booked': '/phone_calls/new_form',
  'NP NOT Booked': '/phone_calls/new_not_booked_form',
  'Existing Patient - Callback': '/phone_calls/new_existing_form',
  'Existing Patient - Complete': '/phone_calls/new_existing_form',
  Message: '/phone_calls/new_existing_form',
  'Lead Not Booked': '/phone_calls/new_existing_form',
};

export default class extends Controller {
  static targets = [
    'practiceSelect',
    'callTypeSelect',
    'fieldsContainer',
    'templateResults',
  ];

  declare practiceSelectTarget: HTMLSelectElement;
  declare callTypeSelectTarget: HTMLSelectElement;
  declare fieldsContainerTarget: HTMLElement;
  declare templateResultsTarget: HTMLElement;

  private templateUpdateCallback: (() => void) | null = null;

  connect() {
    this.showRelevantFields();

    console.log(this.practiceSelectTarget);
    jQuery(this.practiceSelectTarget).on(
      'change.select2',
      function () {
        this.showRelevantFields();
      }.bind(this),
    );
  }

  showSection(trigger: HTMLElement, target: HTMLElement) {
    target.dataset.status = 'open';
    target.classList.remove('d-none');

    const icon = trigger.querySelector<HTMLElement>('i.fa');
    if (icon !== null) {
      icon.classList.remove('fa-plus');
      icon.classList.add('fa-minus');
    }
  }

  hideSection(trigger: HTMLElement, target: HTMLElement) {
    target.dataset.status = 'closed';
    target.classList.add('d-none');

    const icon = trigger.querySelector<HTMLElement>('i.fa');
    if (icon !== null) {
      icon.classList.remove('fa-minus');
      icon.classList.add('fa-plus');
    }
  }

  toggleSection(event: ToggleSectionEvent) {
    const targetElement = this.element.querySelector<HTMLElement>(
      event.params.toggle,
    );
    if (targetElement === null) {
      return;
    }

    // Toggle the content section
    if (targetElement.dataset.status === 'open') {
      this.hideSection(event.currentTarget as HTMLElement, targetElement);
    } else {
      this.showSection(event.currentTarget as HTMLElement, targetElement);
    }
  }

  showRelevantFields() {
    if (this.templateUpdateCallback !== null) {
      this.element.removeEventListener('change', this.templateUpdateCallback);
    }

    const type = this.callTypeSelectTarget.value as CallType;
    if (type === 'NP Booked' || type === 'Recare Booked') {
      this.templateUpdateCallback = () => {
        axios
          .post<{ template: string }>(
            `/phone_calls/render_template`,
            this.generateFormData(),
          )
          .then((response) => {
            this.templateResultsTarget.innerHTML = response.data.template;
          });
      };

      this.element.addEventListener(
        'change',
        this.templateUpdateCallback,
        false,
      );
    }

    axios
      .post(`${formTypePaths[type]}`, this.generateFormData())
      .then(async (response) => {
        this.fieldsContainerTarget.innerHTML = response.data;
        // @ts-ignore
        jQuery('.phone-mask', this.fieldsContainerTarget).inputmask(
          '+1 (999) 999-9999',
        );

        const setupDateTimePickerElement = ($el: JQuery<HTMLElement>) => {
          const $input = $('input', $el);
          const $icon = $('.input-group-append', $el);

          const pickerId = `${$input.prop('id')}-picker`;

          $el.prop('id', pickerId);
          $input.attr('data-target', `#${pickerId}`);
          $icon.attr('data-target', `#${pickerId}`);
        };

        jQuery('.date-time-picker', this.element).each((index, el) => {
          const $el = jQuery(el);
          setupDateTimePickerElement($el);

          // @ts-ignore
          $el.datetimepicker({
            focusOnShow: false,
            icons: {
              time: 'fa fa-clock',
              date: 'fa fa-calendar',
              up: 'fa fa-arrow-up',
              down: 'fa fa-arrow-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-calendar-check-o',
              clear: 'fr fa-trash',
              close: 'fa fa-times',
            },
          });
        });

        jQuery('.date-picker', this.element).each((index, el) => {
          const $el = $(el);
          setupDateTimePickerElement($el);

          // @ts-ignore
          $el.datetimepicker({
            format: 'MM/DD/YYYY',
            focusOnShow: false,
            icons: {
              time: 'fa fa-clock',
              date: 'fa fa-calendar',
              up: 'fa fa-arrow-up',
              down: 'fa fa-arrow-down',
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right',
              today: 'fa fa-calendar-check-o',
              clear: 'fr fa-trash',
              close: 'fa fa-times',
            },
          });
        });

        const elementsContainers = document.querySelectorAll<HTMLElement>(
          '.card-elements',
        );
        elementsContainers.forEach((elementsContainer) => {
          if (
            type === 'Existing Patient - Complete' ||
            type === 'NP Booked' ||
            type === 'Recare Booked'
          ) {
            elementsContainer.parentElement?.classList.remove('d-none');
          } else {
            elementsContainer.parentElement?.classList.add('d-none');
          }
        });
      });
  }

  private generateFormData() {
    const formData = new FormData(this.element as HTMLFormElement);
    const patientAttribute =
      'phone_call[first_appointment_attributes][patient_attributes]';

    if (formData.get(`${patientAttribute}[first_name]`) !== null) {
      formData.set(
        'phone_call[patient_first_name]',
        formData.get(`${patientAttribute}[first_name]`) || '',
      );
      formData.set(
        'phone_call[patient_last_name]',
        formData.get(`${patientAttribute}[last_name]`) || '',
      );
      formData.set(
        'phone_call[patient_phone]',
        formData.get(`${patientAttribute}[phone]`) || '',
      );
    } else if (formData.get('phone_call[patient_first_name]') !== null) {
      formData.set(
        `${patientAttribute}[first_name]`,
        formData.get('phone_call[patient_first_name]') || '',
      );
      formData.set(
        `${patientAttribute}[last_name]`,
        formData.get('phone_call[patient_last_name]') || '',
      );
      formData.set(
        `${patientAttribute}[phone]`,
        formData.get('phone_call[patient_phone]') || '',
      );
    }

    formData.delete('authenticity_token');
    formData.delete('_method');

    return formData;
  }
}
