import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['input', 'view', 'practice'];

  declare inputTarget: HTMLTextAreaElement;
  declare viewTarget: HTMLElement;
  declare practiceTarget: HTMLSelectElement;

  initialize() {
    // @ts-ignore
    this.render = debounce(this.render, 1000);
  }

  async connect() {
    await this.loadPractice(this.practiceTarget.value);
    await this.loadRender(this.inputTarget.value);
  }

  private async loadPractice(id: string) {
    const response = await axios.get(`/practices/${id}.json`);
    this.inputTarget.value = response.data.notes_template || '';
  }

  private async loadRender(template: string) {
    const response = await axios.post(`/manage/template_variables/preview`, {
      practice_id: this.practiceTarget.value,
      template: template,
    });

    this.viewTarget.innerHTML = response.data;
  }

  async selectPractice(event: Event) {
    const select = event.target as HTMLSelectElement;
    this.loadPractice(select.value);

    const textarea = event.target as HTMLTextAreaElement;
    this.loadRender(textarea.value);
  }

  async render(event: InputEvent) {
    const textarea = event.target as HTMLTextAreaElement;
    this.loadRender(textarea.value);
  }
}
