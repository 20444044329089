import { loadStripe } from '@stripe/stripe-js';

const loadCardElements = async () => {
  const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  const elementsContainers = document.querySelectorAll('.card-elements');
  elementsContainers.forEach((elementsContainer) => {
    const elements = stripe.elements();
    const cardElement = elements.create('card', {
      hidePostalCode: true,
      classes: {
        base: 'form-control h-auto py-2',
      },
    });
    cardElement.mount(elementsContainer);
    cardElement.on('change', async (event) => {
      if (event.complete === true) {
        document.querySelector('.stripe-error').classList.add('d-none');
        const response = await stripe.createToken(cardElement);
        if (response.token === undefined) {
          document.querySelector('.stripe-error').classList.remove('d-none');
        } else {
          document.querySelector(elementsContainer.dataset.target).value =
            response.token.id;
          document
            .querySelectorAll('form .btn-primary')
            .forEach((el) => (el.disabled = false));
        }
      } else {
        if (event.empty) {
          document
            .querySelectorAll('form .btn-primary')
            .forEach((el) => (el.disabled = false));
        } else {
          document
            .querySelectorAll('form .btn-primary')
            .forEach((el) => (el.disabled = true));
        }
      }
    });
  });
};

const toggleFreeAccount = () => {
  const checkbox = document.querySelector('#practice_free_account');
  if (!checkbox) {
    return;
  }

  const subscriptionInfo = document.querySelector('#subscription-info');

  if (checkbox.checked) {
    subscriptionInfo.classList.add('d-none');

    document
      .querySelectorAll('form .btn-primary')
      .forEach((el) => (el.disabled = false));
  } else {
    document
      .querySelectorAll('form .btn-primary')
      .forEach((el) => (el.disabled = true));
  }

  checkbox.addEventListener('change', () => {
    subscriptionInfo.classList.toggle('d-none');

    if (checkbox.checked) {
      document
        .querySelectorAll('form .btn-primary')
        .forEach((el) => (el.disabled = false));
    } else {
      document
        .querySelectorAll('form .btn-primary')
        .forEach((el) => (el.disabled = true));
    }
  });
};

const provideChargeHint = () => {
  const setupFeeEl = document.querySelector(
    '#practice_practice_pricing_info_attributes_setup_fee',
  );
  const recareSetupFeeEl = document.querySelector(
    '#practice_practice_pricing_info_attributes_recare_setup_fee',
  );
  const freeAccEl = document.querySelector('#practice_free_account');
  const chargeHintEl = document.querySelector('#charge-hint');

  const monthlyFeeEl = document.querySelector(
    '#practice_practice_pricing_info_attributes_monthly_fee',
  );
  const recareMonthlyFeeEl = document.querySelector(
    '#practice_practice_pricing_info_attributes_recare_monthly_fee',
  );

  if (!chargeHintEl) {
    return;
  }

  const calculateChargeHint = () => {
    if (freeAccEl !== null && freeAccEl.checked) {
      chargeHintEl.textContent = 'The customer will never be charged.';
      return;
    }

    let totalToday = 0;
    totalToday += parseFloat(setupFeeEl.value || 0);
    totalToday += parseFloat(recareSetupFeeEl.value || 0);

    const monthlyFee = parseFloat(monthlyFeeEl.value || 0);
    const recareMonthlyFee = parseFloat(recareMonthlyFeeEl.value || 0);

    const day = new Date().getDate();
    if (day < 15) {
      totalToday += monthlyFee + recareMonthlyFee;
    } else {
      totalToday += (monthlyFee + recareMonthlyFee) / 2;
    }

    document.querySelector(
      '#charge-hint',
    ).textContent = `The customer will be charged ${totalToday.toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
      },
    )} immediately.`;
  };

  setupFeeEl.addEventListener(
    'change',
    () => {
      calculateChargeHint();
    },
    false,
  );

  recareSetupFeeEl.addEventListener(
    'change',
    () => {
      calculateChargeHint();
    },
    false,
  );

  monthlyFeeEl.addEventListener(
    'change',
    () => {
      calculateChargeHint();
    },
    false,
  );

  recareMonthlyFeeEl.addEventListener(
    'change',
    () => {
      calculateChargeHint();
    },
    false,
  );

  if (freeAccEl !== null) {
    freeAccEl.addEventListener(
      'change',
      () => {
        calculateChargeHint();
      },
      false,
    );
  }

  calculateChargeHint();
};

const provideExistingHint = () => {
  const existingHitEl = document.querySelector('#existing-hint');
  if (!existingHitEl) {
    return;
  }

  const chargeHintEl = document.querySelector('#charge-hint');
  if (document.querySelector('#existing_customer').checked) {
    chargeHintEl.classList.toggle('d-none');
    existingHitEl.classList.toggle('d-none');
  }

  document.querySelector('#existing_customer').addEventListener(
    'change',
    (event) => {
      if (event.target.checked) {
        chargeHintEl.classList.toggle('d-none');
        existingHitEl.classList.toggle('d-none');
      } else {
        chargeHintEl.classList.toggle('d-none');
        existingHitEl.classList.toggle('d-none');
      }
    },
    false,
  );
};

document.addEventListener(
  'turbolinks:load',
  async () => {
    await loadCardElements();
    toggleFreeAccount();
    provideChargeHint();
    provideExistingHint();
  },
  false,
);
